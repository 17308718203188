<template>
  <div>
    <user-profile-dialog-edit-enterprise-layout
      v-if="switchEdit"
      :texts="texts"
      :tab="tab"
      :img="logoimage.lg"
      @changeSwitchEdit="changeSwitchEdit"
    />
    <v-container
      v-else
      fluid
      class="border-top pa-0 pt-5 pb-9"
    >
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <div class="display-flex align-items-center justify-content-center height-100 width-100">
            <div class="content-image">
              <img
                v-if="Object.keys(logoimage).length > 0"
                :src="logoimage.lg"
                alt=""
              >
              <img
                v-else
                src="@/assets/images/placeholder-image.jpeg"
                alt=""
              >
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="9"
        >
          <div>
            <div class="card-content">
              <div class="display-flex align-items-center border-bottom pa-4">
                <p class="text-header mon-medium">{{ texts.userprofile.tab2.table.textHeader1 }}</p>
                <v-spacer></v-spacer>
                <p
                  v-if="enterpriseArr.sName"
                  class="text-result mon-regular"
                >{{ enterpriseArr.sName }}</p>
                <empty-text-component v-else />
              </div>
              <div class="display-flex align-items-center border-bottom pa-4">
                <p class="text-header mon-medium">{{ texts.userprofile.tab2.table.textHeader3 }}</p>
                <v-spacer></v-spacer>
                <p
                  v-if="enterpriseArr.sEmail"
                  class="text-result mon-regular"
                >{{ enterpriseArr.sEmail }}</p>
                <empty-text-component v-else />
              </div>
              <div class="display-flex align-items-center border-bottom pa-4">
                <p class="text-header mon-medium">{{ texts.userprofile.tab2.table.textHeader12 }}</p>
                <v-spacer></v-spacer>
                <p
                  v-if="enterpriseArr.sTaxpayerId"
                  class="text-result mon-regular"
                >{{ enterpriseArr.sTaxpayerId }}</p>
                <empty-text-component v-else />
              </div>
              <div class="display-flex align-items-center border-bottom pa-4">
                <p class="text-header mon-medium">{{ texts.userprofile.tab2.table.textHeader4 }}</p>
                <v-spacer></v-spacer>
                <p
                  v-if="enterpriseArr.sPhoneNumber"
                  class="text-result mon-regular"
                >{{ enterpriseArr.sPhoneNumber }}</p>
                <empty-text-component v-else />
              </div>
              <div class="display-flex align-items-center pa-4">
                <p class="text-header mon-medium">{{ texts.userprofile.tab2.table.textHeader11 }}</p>
                <v-spacer></v-spacer>
                <p
                  v-if="enterpriseArr.sDescription"
                  class="text-result mon-regular"
                >{{ enterpriseArr.sDescription }}</p>
                <empty-text-component v-else />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="7"
        >
          <div>
            <div class="card-content">

              <div class="display-flex align-items-center border-bottom pa-4">
                <p class="text-header mon-medium">{{ texts.userprofile.tab2.table.textHeader2 }}</p>
                <v-spacer></v-spacer>
                <p
                  v-if="enterpriseArr.sLocationAddress"
                  class="text-result mon-regular"
                >{{ enterpriseArr.sLocationAddress }}</p>
                <empty-text-component v-else />
              </div>
              
              <div class="display-flex align-items-center border-bottom pa-4">
                <p class="text-header mon-medium">
                  {{
                    (selectLanguage == 'sp')
                    ? 'Dirección específica'
                    : 'Specific address'
                  }}
                </p>
                <v-spacer></v-spacer>
                <p
                  v-if="enterpriseArr.sLocationOptionalAddress"
                  class="text-result mon-regular"
                >{{ enterpriseArr.sLocationOptionalAddress }}</p>
                <empty-text-component v-else />
              </div>

              <div class="display-flex align-items-center border-bottom pa-4">
                <p class="text-header mon-medium">{{ texts.userprofile.tab2.table.textHeader5 }}</p>
                <v-spacer></v-spacer>
                <p
                  v-if="enterpriseArr.sLocationCity"
                  class="text-result mon-regular"
                >{{ enterpriseArr.sLocationCity }}</p>
                <empty-text-component v-else />
              </div>
              <div class="display-flex align-items-center border-bottom pa-4">
                <p class="text-header mon-medium">{{ texts.userprofile.tab2.table.textHeader6 }}</p>
                <v-spacer></v-spacer>
                <p
                  v-if="enterpriseArr.sStateName"
                  class="text-result mon-regular"
                >{{ enterpriseArr.sStateName }}</p>
                <empty-text-component v-else />
              </div>
              <div class="display-flex align-items-center border-bottom pa-4">
                <p class="text-header mon-medium">{{ texts.userprofile.tab2.table.textHeader7 }}</p>
                <v-spacer></v-spacer>
                <p
                  v-if="enterpriseArr.sCountryName"
                  class="text-result mon-regular"
                >{{ enterpriseArr.sCountryName }}</p>
                <empty-text-component v-else />
              </div>
              <div class="display-flex align-items-center border-bottom pa-4">
                <p class="text-header mon-medium">{{ texts.userprofile.tab2.table.textHeader8 }}</p>
                <v-spacer></v-spacer>
                <p
                  v-if="enterpriseArr.sLocationZipCode"
                  class="text-result mon-regular"
                >{{ enterpriseArr.sLocationZipCode }}</p>
                <empty-text-component v-else />
              </div>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <GmapMap
            :center="center"
            :zoom="4"
            :options="mapOptions"
            style="width: 100%; height: 270px;"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              @click="center = m.position"
            />
          </GmapMap>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div class="border-bottom" />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div class="ajust-button display-flex align-items-center justify-content-flex-end">
            <v-btn
              @click="switchEdit = true"
              class="button-yellow mon-bold ml-1"
              elevation="0"
            >
              {{ texts.userprofile.tab1.textButtonModify }}
              <v-icon
                class="ml-1"
                color="#283c4d"
                size="16px"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "UserProfileContentTabTwo",
  props: {
    texts: {
      type: Object,
    },
    tab: {
      type: Number,
    },
  },
  data() {
    return {
      //VARIABLES
      //Logo
      logoimage: "",

      enterpriseArr: [],
      entperiseAddress: "",
      enterpriseLatitude: "",
      enterpriseLongitude: "",

      // change screen in edit
      switchEdit: false,

      // google maps
      center: { lat: 37.09024, lng: -95.712891 },
      currentPlace: null,
      markers: [],
      mapOptions: {
        scrollwheel: false,
        mapTypeControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        streetViewControl: false,
        maxZoom: 10,
        minZoom: 3,
      },
      autocompleteOptions: {
        componentRestrictions: {
          country: ["us", "mx"],
        },
      },
    };
  },
  beforeMount() {
    this.getEnterpriseData();
  },
  methods: {
    changeSwitchEdit: function (val) {
      this.switchEdit = val;
    },
    setPlace: function (place) {
      let split_name = place.formatted_address.split(",");
      let name_address = split_name[0] + "," + split_name[1];

      this.entperiseAddress = name_address;
      this.currentPlace = place;
      this.addMarker();
    },
    addMarker: function () {
      this.markers = [];

      if (this.currentPlace) {
        this.enterpriseLatitude = this.currentPlace.geometry.location.lat();
        this.enterpriseLongitude = this.currentPlace.geometry.location.lng();

        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.center = marker;
        this.currentPlace = null;
      }
    },
    openDialogEditEnterprise: function () {
      this.$store.commit("setProfileDialogEditEnterprise", {
        active: true,
        arr: this.enterpriseArr,
      });
    },
    getEnterpriseData: function () {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}`,
        config
      )
        .then((response) => {
          let arr = response.data.results;
          //data location
          this.enterpriseArr = arr;
          this.logoimage = arr.oVendorLogo.oImages;
          this.entperiseAddress = arr.sLocationAddress;
          this.enterpriseLatitude = arr.dLocationLatitude;
          this.enterpriseLongitude = arr.dLocationLongitude;
          const marker = {
            lat: arr.dLocationLatitude,
            lng: arr.dLocationLongitude,
          };
          this.markers.push({ position: marker });
          this.center = marker;
          this.currentPlace = null;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getEnterpriseData();
      }
    },
    refresh: function () {
      this.getEnterpriseData();
    },
    tab: function () {
      if (this.tab == 1) {
        this.getEnterpriseData();
      }
    },
  },
};
</script>

<style scoped>
.content-image {
  width: 230px;
  height: 230px;
  border-radius: 100%;
  background-color: white;
  /* background: transparent linear-gradient(10deg, #fffc81 0%, #7eff86 100%) 0% 0%
    no-repeat padding-box; */
  box-shadow: 0px 3px 6px #00000029;
  padding: 3px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 100%;
  }
}

.button-gray {
  width: 170px;
  height: 40px !important;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 12px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.button-yellow {
  width: 170px;
  height: 40px !important;
  background: transparent linear-gradient(259deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.text-header {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-result {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}

.border-top {
  border-top: 1px solid #e0e0e0;
}

@media (max-width: 500px) {
  .ajust-button {
    display: block;
  }
  .ajust-button button {
    width: 100%;
    margin-bottom: 15px;
  }
}
</style>